<template>
	<div class="container--882 mypage">
		<main-header></main-header>
		<div class="mypage__header">
			<h1>회원 탈퇴</h1>
			<span>
				사용 중인 아이디는 회원 탈퇴 후 재사용 및 복구가 불가하오니, 신중하게
				선택하시기 바랍니다.
				<br />탈퇴 후 회원정보 및 이용기록은 모두 삭제되며, 삭제된 데이터는
				복구되지 않습니다.
			</span>
		</div>
		<div class="mypage__wrapper withdrawal">
			<div class="mypage__title">
				<h2>회원 탈퇴 완료</h2>
			</div>
			<div class="mypage__withdrawal-text">
				<p>FCTS 회원 탈퇴가 완료되었습니다.</p>
				<p>
					그동안 FCTS 서비스를 아끼고 사랑해 주셔서 고맙습니다.<br />
					더욱더 노력하고 발전하는 FCTS가 되겠습니다.
				</p>
			</div>
			<div class="userguide_btnbox">
				<button class="btn--md btn--fill-accent">
					<router-link to="/">홈으로</router-link>
				</button>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from './Footer';
import MainHeader from './MainHeader';
export default {
	components: {
		Footer,
		MainHeader,
	},
	data() {
		return {
			withdrawal: {
				selected: '',
				agree: '',
				userPw: '',
			},
		};
	},
	computed: {
		withdrawalCheck() {
			if (
				this.withdrawal.userPw &&
				this.withdrawal.agree &&
				this.withdrawal.selected
			) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		// 회원 탈퇴 처리 +  비밀번호 일치 여부 확인하기
		submitForm() {
			let returnValue = confirm('정말 탈퇴하시겠습니까?');

			if (returnValue == true) {
				this.$emit('changeStep', 10);
			}
		},
	},
};
</script>

<style></style>
